.recently-created-container {
  width: 100%;
  text-align: center;
  color: #fff;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-wrapper {
  overflow: hidden;
  margin-bottom: 15px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color:#fff;

}

.carousel-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.carousel-item-content img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
  border-radius: 500%;
  border: 1px solid #fff;
}

.carousel-item-content span {
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}