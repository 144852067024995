#root{
  width: 100%;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
}
nav .logo{
  padding: 20px 0 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
nav .logo img{
  width: 200px;
}
.nav-links {
  margin-left: auto;
  margin-top: 18px;
  display: flex;
  gap: 1.5rem;
  padding-right: 1rem; /* Add some padding to prevent touching the edge */
}
.nav-links a{
  cursor: pointer;
  color: #fff;
}
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: 
    url("/public/shill-space-background.webp"),
    linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(225,22,238) 100%);
    background-position: bottom;
    background-repeat: no-repeat, no-repeat;
}
.title-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.title-container h1 {
  color: #fff;
}
form label{
  text-align: left;
}
.checkbox-container {
  text-align: left;
}
.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

h1 {
  margin-bottom: 0.5rem;
  color: #06111D;
}
h2 {
  text-align: left;
  margin-top:0;
  margin-bottom: 0.5rem;
  font-size: 1.7em;

}
.subtitle{
  text-align: left;
  margin-top:0;
  font-size: 1rem;
  color: #666;
}
form {
  display: flex;
  flex-direction: column;
}

input, textarea, select {
  margin-bottom: 1rem;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.custom-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-link input {
  flex: 1;
  margin-right: 10px;
}

.remove-link-btn {
  background-color: transparent;
  color: #000;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease;
}

.remove-link-btn:hover {
  background-color: transparent;
}

button {
  background-color: #fff;
  color: #06111D;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  cursor: pointer;
}
button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.add-link-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 1rem;
}

.add-link-btn:hover {
  background-color: #45a049;
}
.new-site-btn {
  background-color: #008CBA;
  margin-top: 25px;
  color: white;
}
.success-message{
  text-align: left;
}
.error {
  color: #d32f2f;
  background-color: #ffcdd2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 1rem;
}
/* Responsive design */
@media (max-width: 600px) {
  .form-container {
    padding: 1rem;
  }

  .custom-link {
    flex-direction: column;
    gap: 0.5rem;
  }

  .custom-link input {
    margin-bottom: 0.5rem;
  }
}
.edit-btn{
  width: 100%;
  margin-top: 10px;
  font-weight: 400;
}

.slug-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.slug-prefix {
  padding: 10px;
  background-color: #f0f0f0;
  color: #666;
  white-space: nowrap;
  border-right: 1px solid #ccc;
}

.slug-input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  margin-bottom: 0;
}

.slug-input:focus {
  outline: none;
}

.slug-input-container:focus-within {
  border-color: #007bff;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.add-link-btn{
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-link-btn {
  width: 50%;
  background-color: #f0f0f0;
  color: #333;
}

.add-link-btn:hover {
  background-color: #e0e0e0;
}

.submit-btn {
  background-color: #020024;
  color: #fff;
  width: 100%;
}
.submit-btn:hover {
  background-color: #020024;
}
.custom-link-input{
  margin-bottom: 0;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}
.success-image{
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}
.form-logo{
  width: 50px;
  margin: 20px;
}

.login-button-container {
  margin-left: 1rem;
}

.login-link {
  cursor: pointer;
  color: white;
  background-color: #020024;  /* Blue color */
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .nav-link {
    display: none;
  }
}