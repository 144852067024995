.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 90%;
  }
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .auth-form input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .auth-form button {
    padding: 0.5rem 1rem;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .auth-form button:disabled {
    background-color: #ccc;
  }
  
  .message {
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
  }
  
  .message.error {
    background-color: #fee2e2;
    color: #dc2626;
  }
  
  .message.success {
    background-color: #dcfce7;
    color: #16a34a;
  }