.my-sites-container {
  max-width: 800px;  /* or whatever width you prefer */
  margin: 2rem auto;  /* centers the container and adds vertical spacing */
  padding: 2rem;
  background-color: white;
  border-radius: 8px;  /* rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);  /* subtle shadow */
}
.table-button {
    font-size: 0.8rem;
    padding: 10px;
    margin-right: 5px;
}
